import { create } from "zustand";

export const useModalStore = create((set) => ({
  type: null,
  isShow: false,
  selectedData: null,
  isDisabled: true,
  setType: (props) => set((state) => ({ ...state, type: props })),
  setIsShow: (props) => set((state) => ({ ...state, isShow: props })),
  selectData: (props) => set((state) => ({ ...state, selectedData: props })),
  setIsDisabled: (props) => set((state) => ({ ...state, isDisabled: props })),
}));
