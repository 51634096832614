import { useState } from "react";
import { Form } from "antd";

export const useForm = (props) => {
  const [form] = Form.useForm();
  const [customForm, setCustomForm] = useState({ isFinish: false, ...props });

  const getFieldValue = (name) => {
    if (name) return customForm[name];
    else return customForm;
  };

  const setFieldValue = (props) => {
    setCustomForm((prev) => ({ ...prev, ...props }));
  };

  const resetFieldValue = () => {
    setCustomForm({ ...customForm, ...props });
  };

  const resetField = () => {
    form.resetFields();
    resetFieldValue();
  };

  const submit = () => {
    form.submit();
  };

  return [
    {
      ...form,
      getFieldValue,
      setFieldValue,
      resetFieldValue,
      resetField,
      submit,
    },
  ];
};
