import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

//Auth View
const OldLogin = lazy(() => import("./views/auth/Login"));
const Join = lazy(() => import("./views/auth/Join"));
const SetPw = lazy(() => import("./views/auth/SetPw"));

//Page View
const MainPage = lazy(() => import("./views/main/MainPage"));
const Project = lazy(() => import("./views/main/Project"));
const SubjectList = lazy(() => import("./views/main/Subject"));
const Dashboard = lazy(() => import("./views/main/Dashboard"));
const QueryList = lazy(() => import("./views/main/QueryPage"));
const QueryDetail = lazy(() => import("./views/main/QueryPage/QueryDetail"));
const NewQuery = lazy(() => import("./views/main/QueryPage/NewQuery"));
const OldESign = lazy(() => import("./pages/e-sign/OldESign"));
const OldESignEdit = lazy(() => import("./pages/e-sign/OldESignEdit"));
const ESignListProgress = lazy(() => import("./pages/e-sign/OldESignListProgress"));
const ESignListDone = lazy(() => import("./pages/e-sign/OldESignListDone"));
const MultiFormPopup = lazy(() => import("./views/main/Dashboard/SideMenu/MultiFormPopup"));
const ImageViewPopup = lazy(() => import("./views/main/Dashboard/SideMenu/ImageViewPopup"));
const DataImportPopup = lazy(() => import("./views/admin/ProjectManagement/Import/DataImportPopup"));
const ImageImportPopup = lazy(() => import("./views/admin/ProjectManagement/Import/ImageImportPopup"));

//Admin Page
const UserManagement2 = lazy(() => import("./views/admin/UserManagement"));
const Notice = lazy(() => import("./views/admin/Notice"));
const ProjectManagement2 = lazy(() => import("./views/admin/ProjectManagement"));
const AuthManagement = lazy(() => import("./views/admin/AuthManagement"));
const ExportAuditManagement = lazy(() => import("./views/admin/ExportAuditManagement"));

//Test Page
const UploadPage = lazy(() => import("./views/Import"));

//Layout
const OldAuthLayout = lazy(() => import("./layouts/AuthLayout"));
const MainPageLayout = lazy(() => import("./layouts/MainPageLayout"));
const ProjectLayout = lazy(() => import("./layouts/ProjectLayout"));
const SubjectLayout = lazy(() => import("./layouts/SubjectLayout"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const AdminLayout = lazy(() => import("./layouts/AdminLayout"));
const NewWindowLayout = lazy(() => import("./layouts/NewWindowLayout"));
const QueryPageLayout = lazy(() => import("./layouts/QueryPageLayout"));
const ESignLayout = lazy(() => import("./layouts/ESignLayout"));
const WindowClose = lazy(() => import("./views/WindowClose"));

// Layout
const WrapLayout = lazy(() => import("./components/common/layouts/WrapLayout"));
const AuthLayout = lazy(() => import("./components/common/layouts/AuthLayout"));
const FullScreenLayout = lazy(() => import("./components/common/layouts/FullScreenLayout"));
const ModuleLayout = lazy(() => import("./components/common/layouts/ModuleLayout"));

// Error Page
const AwaitingLaunch = lazy(() => import("./pages/error/AwaitingLaunch"));
const ContactAdminError = lazy(() => import("./pages/error/ContactAdminError"));
const NotFoundError = lazy(() => import("./pages/error/NotFoundError"));

// Auth Page
const AwaitingApproval = lazy(() => import("./pages/auth/PendingApproval"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const Login = lazy(() => import("./pages/auth/Login"));
const Signup = lazy(() => import("./pages/auth/Signup"));

// Full Window Page
const PrivacyPolicy = lazy(() => import("./pages/policy/PrivacyPolicy"));
const QuestionPdfPreview = lazy(() => import("./pages/question/QuestionPdfPreview"));

// Admin Page
const UserManagement = lazy(() => import("./pages/admin/UserManagement"));
const ProjectManagement = lazy(() => import("./pages/admin/ProjectManagement"));

// Main Page
const ProjectList = lazy(() => import("./pages/project/ProjectList"));

// Question Page
const QuestionList = lazy(() => import("./pages/question/QuestionList"));
const QuestionDetail = lazy(() => import("./pages/question/QuestionDetail"));
const AddQuestion = lazy(() => import("./pages/question/AddQuestion"));

// ESign Page
const ESignList = lazy(() => import("./pages/e-sign/ESignList"));
const ESignEdit = lazy(() => import("./pages/e-sign/ESignEdit"));

// Chart Page
const Treatment = lazy(() => import("./pages/chart/Treatment"));
const LabPatho = lazy(() => import("./pages/chart/LabPatho"));

const routes = [
  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to="/auth/login" /> },
      { path: "404", element: <NotFoundError /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  {
    path: "/auth",
    element: <OldAuthLayout />,
    children: [
      { path: "login", element: <OldLogin /> },
      { path: "join", element: <Join /> },
      { path: "set-pw", element: <SetPw /> },
      // { path: 'test', element: <WebSocketTest /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/main",
    element: <MainPageLayout />,
    children: [
      { path: "", element: <MainPage /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/project",
    element: <ProjectLayout />,
    children: [
      { path: "", element: <Project /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // old project
  {
    path: "/project/:projectId/*",
    element: <SubjectLayout />,
    children: [
      { path: "subject", element: <SubjectList /> },
      { path: "question/list", element: <QueryList /> },
      {
        path: "old-e-sign/",
        element: <ESignLayout />,
        children: [
          { path: "list", element: <OldESign /> },
          { path: "list-progress", element: <ESignListProgress /> },
          { path: "list-done", element: <ESignListDone /> },
        ],
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  // old question
  {
    path: "/question/*",
    element: <QueryPageLayout />,
    children: [
      { path: ":queryId/:state", element: <QueryDetail /> },
      { path: "new", element: <NewQuery /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  // old subject
  {
    path: "/subject/:subjectId/*",
    element: <DashboardLayout />,
    children: [
      { path: "dashboard", element: <Dashboard /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  // old dashboard
  {
    path: "/dashboard/:subjectId/*",
    element: <NewWindowLayout />,
    children: [
      { path: "multi-report", element: <MultiFormPopup /> },
      { path: "image-view", element: <ImageViewPopup /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  // old e-sign
  // {
  //   path: "/e-sign/",
  //   element: <NewWindowLayout backgroundColor="rgb(248, 248, 248)" />,
  //   children: [
  //     { path: "edit", element: <OldESignEdit /> },
  //     { path: "*", element: <Navigate to="/404" /> },
  //   ],
  // },
  // {
  //   path: '/video-conference/',
  //   element: <NewWindowLayout />,
  //   children: [
  //     { path: 'meeting', element: <Meeting /> },
  //     { path: '*', element: <Navigate to="/404" /> },
  //   ],
  // },
  // {
  //   path: '/dashboard/:subjectId/chart/*',
  //   element: <ChartLayout />,
  //   children: [
  //     { path: 'treatment', element: <Treatment /> },
  //     { path: 'lab-patho', element: <LabPatho /> },
  //     { path: '*', element: <Navigate to="/404" /> },
  //   ],
  // },
  // old admin
  {
    path: "/admin/*",
    element: <AdminLayout />,
    children: [
      { path: "", element: <UserManagement2 /> },
      { path: "users", element: <UserManagement2 /> },
      { path: "notice", element: <Notice /> },
      { path: "project", element: <ProjectManagement2 /> },
      { path: "authority", element: <AuthManagement /> },
      { path: "data-audit", element: <ExportAuditManagement /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/admin/project/:projectPk/",
    element: <NewWindowLayout />,
    children: [
      { path: ":fileType/data-import", element: <DataImportPopup /> },
      { path: ":fileType/image-import", element: <ImageImportPopup /> },

      // { path: 'SUBJECT_INFO', element: <DataImportPopup /> },
      // { path: 'DISEASE_INFORMATION', element: <DataImportPopup /> },
      // { path: 'MEDICAL_HISTORY', element: <DataImportPopup /> },
      // { path: 'TREATMENT', element: <DataImportPopup /> },
      // { path: 'LAB-PATHO', element: <DataImportPopup /> },
      // { path: 'IMAGE', element: <ImageImportPopup /> },
      // { path: 'RESPONSE', element: <DataImportPopup /> },
      // { path: 'AE_REPORT', element: <DataImportPopup /> },
      // { path: 'REPORT_TL', element: <DataImportPopup /> },
      // { path: 'REPORT_NTL', element: <DataImportPopup /> },
      // { path: 'REPORT_NL', element: <DataImportPopup /> },
      // { path: 'REPORT_OR', element: <DataImportPopup /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  {
    path: "/window-close",
    element: <WindowClose />,
  },
  {
    path: "/test",
    element: <UploadPage />,
  },

  // error
  {
    path: "/",
    children: [
      { path: "awaiting-launch", element: <AwaitingLaunch /> },
      { path: "500", element: <ContactAdminError /> },
      { path: "404", element: <NotFoundError /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // auth
  {
    path: "/new-auth/*",
    element: <AuthLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/auth/*",
    element: <AuthLayout />,
    children: [
      { path: "signup", element: <Signup /> },
      { path: "pending-approval", element: <AwaitingApproval /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // full screen
  {
    path: "/full-screen/*",
    children: [
      { path: "policy/privacy-policy/:versionDate", element: <PrivacyPolicy /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/full-screen/*",
    element: <FullScreenLayout />,
    children: [
      { path: "project/:projectPk/question/:questionPk/question-pdf-preview", element: <QuestionPdfPreview /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // admin
  {
    path: "/management/*",
    element: <WrapLayout />,
    children: [
      { path: "project-management", element: <ProjectManagement /> },
      { path: "user-management", element: <UserManagement /> },
      { path: "users", element: <UserManagement2 /> },
      { path: "authority", element: <AuthManagement /> },
      { path: "notice", element: <Notice /> },
      { path: "project", element: <ProjectManagement2 /> },
      { path: "data-audit", element: <ExportAuditManagement /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // main
  {
    path: "/new-project/*",
    element: <WrapLayout />,
    children: [
      { path: "", element: <ProjectList /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },

  // project
  {
    path: "/project/:projectPk/*",
    element: <ModuleLayout />,
    children: [
      { path: "question", element: <QuestionList /> },
      { path: "question/:questionPk", element: <QuestionDetail /> },
      { path: "question/add", element: <AddQuestion /> },
      {
        path: "question/:questionPk/question-pdf-preview",
        element: <QuestionPdfPreview />,
      },
      { path: "e-sign", element: <ESignList /> },
      { path: "e-sign/edit", element: <ESignEdit /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
