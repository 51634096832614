import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { lazy, Suspense, useEffect } from "react";
import { useLocation, useNavigate, useRoutes, BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { FloatButton, Modal, Row, Spin } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";
import { ThemeProvider } from "@material-ui/core";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useModal, useResize } from "hooks";
import GlobalStyles from "./components/Old/GlobalStyles";
import Loading from "./components/Old/Loading";

import theme from "./theme";
import routes from "./routes";

//Layout
const OldAuthLayout = lazy(() => import("./layouts/AuthLayout"));
const MainPageLayout = lazy(() => import("./layouts/MainPageLayout"));
const ProjectLayout = lazy(() => import("./layouts/ProjectLayout"));
const SubjectLayout = lazy(() => import("./layouts/SubjectLayout"));
const DashboardLayout = lazy(() => import("./layouts/DashboardLayout"));
const AdminLayout = lazy(() => import("./layouts/AdminLayout"));
const NewWindowLayout = lazy(() => import("./layouts/NewWindowLayout"));
const QueryPageLayout = lazy(() => import("./layouts/QueryPageLayout"));
const ESignLayout = lazy(() => import("./layouts/ESignLayout"));
const WindowClose = lazy(() => import("./views/WindowClose"));

//Auth View
const OldLogin = lazy(() => import("./views/auth/Login"));
const Join = lazy(() => import("./views/auth/Join"));
const SetPw = lazy(() => import("./views/auth/SetPw"));

//Page View
const MainPage = lazy(() => import("./views/main/MainPage"));
const Project = lazy(() => import("./views/main/Project"));
const SubjectList = lazy(() => import("./views/main/Subject"));
const Dashboard = lazy(() => import("./views/main/Dashboard"));
const QueryList = lazy(() => import("./views/main/QueryPage"));
const QueryDetail = lazy(() => import("./views/main/QueryPage/QueryDetail"));
const NewQuery = lazy(() => import("./views/main/QueryPage/NewQuery"));
const OldESign = lazy(() => import("./pages/e-sign/OldESign"));
const OldESignEdit = lazy(() => import("./pages/e-sign/OldESignEdit"));
const ESignListProgress = lazy(() => import("./pages/e-sign/OldESignListProgress"));
const ESignListDone = lazy(() => import("./pages/e-sign/OldESignListDone"));
const MultiFormPopup = lazy(() => import("./views/main/Dashboard/SideMenu/MultiFormPopup"));
const ImageViewPopup = lazy(() => import("./views/main/Dashboard/SideMenu/ImageViewPopup"));
const DataImportPopup = lazy(() => import("./views/admin/ProjectManagement/Import/DataImportPopup"));
const ImageImportPopup = lazy(() => import("./views/admin/ProjectManagement/Import/ImageImportPopup"));

//Admin Page
const UserManagement2 = lazy(() => import("./views/admin/UserManagement"));
const Notice = lazy(() => import("./views/admin/Notice"));
const ProjectManagement2 = lazy(() => import("./views/admin/ProjectManagement"));
const AuthManagement = lazy(() => import("./views/admin/AuthManagement"));
const ExportAuditManagement = lazy(() => import("./views/admin/ExportAuditManagement"));

//Test Page
const UploadPage = lazy(() => import("./views/Import"));

// Layout
const WrapLayout = lazy(() => import("./components/common/layouts/WrapLayout"));
const AuthLayout = lazy(() => import("./components/common/layouts/AuthLayout"));
const FullScreenLayout = lazy(() => import("./components/common/layouts/FullScreenLayout"));
const ModuleLayout = lazy(() => import("./components/common/layouts/ModuleLayout"));

// Error Page
const AwaitingLaunch = lazy(() => import("./pages/error/AwaitingLaunch"));
const ContactAdminError = lazy(() => import("./pages/error/ContactAdminError"));
const NotFoundError = lazy(() => import("./pages/error/NotFoundError"));
const NoAccessError = lazy(() => import("./pages/error/NoAccessError"));

// Auth Page
const PendingApproval = lazy(() => import("./pages/auth/PendingApproval"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const Login = lazy(() => import("./pages/auth/Login"));
const Signup = lazy(() => import("./pages/auth/Signup"));

// Full Window Page
const PrivacyPolicy = lazy(() => import("./pages/policy/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/policy/TermsOfUse"));
const QuestionPdfPreview = lazy(() => import("./pages/question/QuestionPdfPreview"));

// Admin Page
const UserManagement = lazy(() => import("./pages/admin/UserManagement"));
const ProjectManagement = lazy(() => import("./pages/admin/ProjectManagement"));

// Main Page
const ProjectList = lazy(() => import("./pages/project/ProjectList"));

// Question Page
const QuestionList = lazy(() => import("./pages/question/QuestionList"));
const QuestionDetail = lazy(() => import("./pages/question/QuestionDetail"));
const AddQuestion = lazy(() => import("./pages/question/AddQuestion"));

// ESign Page
const ESignList = lazy(() => import("./pages/e-sign/ESignList"));
const ESignEdit = lazy(() => import("./pages/e-sign/ESignEdit"));

const App = () => {
  const routing = useRoutes(routes);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { handleResize, isMobile } = useResize();
  const { resetModal } = useModal();

  const handleClickHelpButton = () => {
    window.open("https://trialinformatics.atlassian.net/servicedesk/customer/portal/3");
  };

  const handlePopState = () => {
    Modal.destroyAll();
    if (!search.includes("isShowModal=true")) {
      resetModal();
    }
  };

  useEffect(() => {
    if (["prod"].includes(process.env.REACT_APP_ENV) && ["new-", "e-sign"].some((path) => pathname.includes(path))) navigate("/awaiting-launch", { replace: true });
  }, [pathname]);

  useEffect(() => {
    // 팝업창 띄우기
    if (window) {
      const hostname = window.location.hostname;
      if (hostname.includes("ti-otb")) {
        window.location.href = `https://${hostname.replace("ti-otb", "titdh")}${window.location.pathname}`;
      }
    }

    handleResize();
    handlePopState();
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense
        fallback={
          <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
            <Spin size="large" />
          </Row>
        }
      >
        <Routes>
          <Route path="/main/*" element={<MainPageLayout />}>
            <Route path="" element={<MainPage />} />
          </Route>

          <Route path="/project/*" element={<ProjectLayout />}>
            <Route path="" element={<Project />} />
          </Route>

          <Route path="/project/:projectId/*" element={<SubjectLayout />}>
            <Route path="subject" element={<SubjectList />} />
            <Route path="question/list" element={<QueryList />} />
            <Route path="old-e-sign/*" element={<ESignLayout />}>
              <Route path="list" element={<OldESign />} />
              <Route path="list-progress" element={<ESignListProgress />} />
              <Route path="list-done" element={<ESignListDone />} />
            </Route>
          </Route>

          <Route path="/question/*" element={<QueryPageLayout />}>
            <Route path=":queryId/:state" element={<QueryDetail />} />
            <Route path="new" element={<NewQuery />} />
          </Route>

          <Route path="/subject/:subjectId/*" element={<DashboardLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
          </Route>

          <Route path="/dashboard/:subjectId/*" element={<NewWindowLayout />}>
            <Route path="multi-report" element={<MultiFormPopup />} />
            <Route path="image-view" element={<ImageViewPopup />} />
          </Route>

          <Route path="/admin/*" element={<AdminLayout />}>
            <Route path="users" element={<UserManagement2 />} />
            <Route path="notice" element={<Notice />} />
            <Route path="project" element={<ProjectManagement2 />} />
            <Route path="authority" element={<AuthManagement />} />
            <Route path="data-audit" element={<ExportAuditManagement />} />
          </Route>

          <Route path="/admin/project/:projectPk/*" element={<NewWindowLayout />}>
            <Route path=":fileType/data-import" element={<DataImportPopup />} />
            <Route path=":fileType/image-import" element={<ImageImportPopup />} />
          </Route>

          <Route path="/old-admin/*" element={<AdminLayout />}>
            <Route path="users" element={<UserManagement2 />} />
            <Route path="notice" element={<Notice />} />
            <Route path="project" element={<ProjectManagement2 />} />
            <Route path="authority" element={<AuthManagement />} />
            <Route path="data-audit" element={<ExportAuditManagement />} />
          </Route>

          <Route path="/old-admin/project/:projectPk/*" element={<NewWindowLayout />}>
            <Route path=":fileType/data-import" element={<DataImportPopup />} />
            <Route path=":fileType/image-import" element={<ImageImportPopup />} />
          </Route>

          {["prod"].includes(process.env.REACT_APP_ENV) ? (
            <>
              <Route path="/auth/*" element={<OldAuthLayout />}>
                <Route path="login" element={<OldLogin />} />
                <Route path="join" element={<Join />} />
                <Route path="set-pw" element={<SetPw />} />
              </Route>
            </>
          ) : (
            <>
              <Route path="/auth/*" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
              </Route>
              <Route path="/auth/*" element={<OldAuthLayout />}>
                <Route path="join" element={<Join />} />
                <Route path="set-pw" element={<SetPw />} />
              </Route>
            </>
          )}

          <Route path="/auth/*" element={<AuthLayout />}>
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="pending-approval" element={<PendingApproval />} />
          </Route>

          <Route path="/full-screen/*">
            <Route path="policy/privacy-policy/:versionDate" element={<PrivacyPolicy />} />
            <Route path="policy/terms-of-use/:versionDate" element={<TermsOfUse />} />
          </Route>
          <Route path="/full-screen/*" element={<FullScreenLayout />}>
            <Route path="project/:projectPk/question/:questionPk/question-pdf-preview" element={<QuestionPdfPreview />} />
          </Route>

          <Route path="/management/*" element={<WrapLayout />}>
            <Route path="project-management" element={<ProjectManagement />} />
            <Route path="user-management" element={<UserManagement />} />
            <Route path="users" element={<UserManagement2 />} />
            <Route path="authority" element={<AuthManagement />} />
            <Route path="notice" element={<Notice />} />
            <Route path="project" element={<ProjectManagement2 />} />
            <Route path="data-audit" element={<ExportAuditManagement />} />
            <Route path="*" element={<Navigate to="/management/project-management" />} />
          </Route>

          <Route path="/new-project/*" element={<WrapLayout />}>
            <Route path="" element={<ProjectList />} />
          </Route>

          <Route path="/project/:projectPk/*" element={<ModuleLayout />}>
            <Route path="question" element={<QuestionList />} />
            <Route path="question/:questionPk" element={<QuestionDetail />} />
            <Route path="question/add" element={<AddQuestion />} />
            <Route path="question/:questionPk/question-pdf-preview" element={<QuestionPdfPreview />} />
            <Route path="e-sign" element={<ESignList />} />
            <Route path="e-sign/edit" element={<ESignEdit />} />
          </Route>

          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route path="/window-close" element={<WindowClose />} />
          <Route path="/test" element={<UploadPage />} />
          <Route path="/awaiting-launch" element={<AwaitingLaunch />} />
          <Route path="/500" element={<ContactAdminError />} />
          <Route path="/404" element={<NotFoundError />} />
          <Route path="/403" element={<NoAccessError />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
        {!pathname.includes("edit") && !isMobile && <FloatButton icon={<CustomerServiceOutlined />} onClick={handleClickHelpButton} />}
      </Suspense>
      <ReactQueryDevtools initialIsOpsen />
    </ThemeProvider>
  );
};

export default App;
